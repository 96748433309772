@import "../../../data/styles.css";

.exp {
    mix-blend-mode: normal;
    border-radius: 20px;
    opacity: 0.8;
    height: 100%;
    background-color: snow;
}

.exp a {
    text-decoration: none;
}

.exp:hover {
    background: rgba(33, 69, 107, 0.12);
    opacity: 1;
    transition: background-color 0.3s ease-in-out;
}

.exp:hover .exp-link {
    color: var(--link-color);
    transition: color 0.3s ease-in-out;
}

.exp-container {
    padding: 30px;
}

.exp-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 50px;
    overflow: hidden;
}

.exp-logo img {
    width: 100%;
    height: auto;
}

.exp-title {
    font-family: var(--secondary-font);
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 700;
    padding-top: 15px;
    margin-bottom: 5px;
}

.exp-role {
    color: var(--primary-color);
    font-size: smaller;
    font-style: italic;
}

.exp-duration {
    color: var(--secondary-color);
    font-size: smaller;
    font-style: italic;
}

.exp-description {
    color: var(--secondary-color);
    padding-top: 10px;
}

.exp-link {
    display: flex;
    align-items: center;
    color: var(--secondary-color);
    font-size: 12px;
    padding-top: 20px;
}

.exp-link-icon {
    padding-left: 5px;
    font-size: 13px;
}

.exp-link-text {
    padding-left: 20px;
    font-weight: 700;
}

@media (max-width: 600px) {
    .exp {
        height: 300px;
    }
}
