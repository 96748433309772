@import "../../../data/styles.css";

.social,
.email-wrapper {
    display: flex;
    padding-bottom: 20px;
}

.social a,
.email-wrapper a {
    text-decoration: none;
}

.social-icon {
    font-size: 15px;
    color: var(--secondary-color);
}

.social-text {
    color: var(--secondary-color);
    padding-left: 30px;
    margin-top: -20px;
    font-weight: 550;
    font-size: 14px;
}

.email::before {
    content: "";
    display: block;
    padding-top: 20px;
    border-top: 3px solid #f4f4f5;
    margin-top: 20px;
}

.email-wrapper {
    padding-top: 20px;
}

.social-text:hover {
    color: var(--link-color)
}
