@import "../../../data/styles.css";

.nav-container {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 3vh;
    z-index: 999;
}

.nav-background {
    width: 380px;
    height: 40px;
    background: snow;
    border-radius: 40px;
}

.nav-list {
    display: flex;
    justify-content: space-between;
    list-style: none;
    align-items: center;
    margin-left: -16px;
    margin-right: 20px;
    margin-top: 11px;
}

.nav-item {
    font-weight: bold;
    font-size: 80%;
}

.nav-item.active a {
    color: var(--link-color) !important;
}

.nav-item a {
    text-decoration: none;
    color: var(--primary-color);
}

.nav-item a:hover {
    color: var(--link-color);
    transition: color 0.3s ease-in-out;
}

@media (max-width: 600px) {
    .navbar {
        margin-left: 25%;
        margin-right: 25%;
        width: 80%;
        font-size: 80%;
    }

    .nav-background {
        width: 80%;
        height: 40px;
    }

    .nav-item {
        font-weight: bold;
    }
}
