@import "../../../data/styles.css";

.project {
    mix-blend-mode: normal;
    border-radius: 20px;
    opacity: 0.8;
    height: 100%;
    background-color: snow;
}

.project a {
    text-decoration: none;
}

.project:hover {
    background: rgba(33, 69, 107, 0.12);
    opacity: 1;
    transition: background-color 0.3s ease-in-out;
}

.project:hover .project-link {
    color: var(--link-color);
    transition: color 0.3s ease-in-out;
}

.project-container {
    padding: 30px;
}

.project-screenshot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 50px;
    overflow: hidden;
}

.project-screenshot img {
    width: 100%;
    height: auto;
}

.project-title {
    font-family: var(--secondary-font);
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 250;
    padding-top: 15px;
    margin-bottom: 5px;
}

.project-description {
    color: var(--secondary-color);
    padding-top: 10px;
    font-size: 12px;
    font-weight: 150;
}

.project-link {
    display: flex;
    align-items: center;
    color: var(--secondary-color);
    font-size: 12px;
    padding-top: 20px;
}

.project-link-icon {
    padding-left: 5px;
    font-size: 13px;
}

.project-link-text {
    padding-left: 20px;
    font-weight: 700;
}

@media (max-width: 600px) {
    .project {
        height: 300px;
    }
}
