.skills-container {
    overflow: hidden;
    white-space: nowrap;
}

.skills-title {
    color: var(--primary-color);
    font-family: var(--secondary-font);
    padding-top: 40px;
    font-size: 20px;
    font-weight: 50;
}

.skills-list {
    display: flex;
    animation: slide 30s linear infinite;
}

.skills-list li {
    list-style: none;
    padding: 0 1em;
    position: relative;
}

.skills-list li::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--primary-color);
    transition: width 0.3s ease-in-out;
}

.skills-list:hover {
    animation-play-state: paused;
}

.skills-list li:hover::after {
    width: 100%;
}

@keyframes slide {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-250%);
    }
}
