.all-projects-container {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    padding-bottom: 30px;
    margin-left: -35px;
    margin-right: -35px;
}

.project-box {
    width: calc(100% / 3);
    box-sizing: border-box;
    padding: 10px;
    overflow: hidden;
}

@media (max-width: 600px) {
    .project-box {
        width: calc(100% / 2);
    }
}
