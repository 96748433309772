.experience-page{
    background-color: rgba(236, 236, 241, 0.76);
}

.experience-ghost-container {
    display: flex;
    justify-content: left;
    padding-top: 25px;
}

.experience-ghost-logo {
    display: flex;
    position: fixed;
    border: 1px solid white;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    top: 4vh;
    z-index: 999;
}

.experience-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding-top: 120px;
}

.work-experience-subtitle {
    color: var(--primary-color);
    font-family: var(--secondary-font);
    padding-top: 30px;
    font-size: 20px;
    font-weight: 50;
}

.enterprise-apps-subtitle {
    color: var(--primary-color);
    font-family: var(--secondary-font);
    padding-top: 40px;
    font-size: 20px;
    font-weight: 50;
}

.experience-box-grid {
    flex-basis: 300px;
}

.projects-subtitle {
    color: var(--primary-color);
    font-family: var(--secondary-font);
    padding-top: 25px;
    font-size: 20px;
    font-weight: 50;
}

.projects-box-grid {
    flex-basis: 300px;
}

@media (max-width: 600px) {
    .experience-container {
        padding-top: 60px;
    }

    .projects-project {
        width: calc(100% / 2);
    }
}
